import * as React from "react";
import { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { makeNetworkCall } from "../../utils/fetchData";
import { MINIFIED_PRODUCTS_ENDPOINT } from "../../utils/constants";

const ProductSelection = ({
  setFormValues,
  setProduct = null,
  productType = "inventories",
}) => {
  const [products, setData] = useState([]);
  useEffect(() => {
    makeNetworkCall(
      MINIFIED_PRODUCTS_ENDPOINT,
      {
        product_type: productType,
      },
      setData,
      null,
      null,
      {},
      "POST"
    ).then((r) => console.log(setData(r.data)));
  }, []);
  let selectedProductIdProduct = "";

  const handleProductSelection = (value, type) => {
    selectedProductIdProduct = value ? value.id : "";
    if (setProduct != null) {
      setProduct(" in " + value.unit_of_measurement);
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      product_id: value ? value.id : "",
    }));
  };

  return (
    <FormControl fullWidth>
      <p>
        {productType}
        <span style={{ color: "red", marginLeft: "5px" }}>* </span>
      </p>
      {
        <Autocomplete
          options={products}
          getOptionLabel={(option) => {
            if (!option.item_number) {
              return option.item_name;
            } else {
              return `${option.item_name} (#${option.item_number})`;
            }
          }}
          value={products.find((data) => data.id === selectedProductIdProduct)}
          onChange={(event, value) => handleProductSelection(value, "product")}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" fullWidth />
          )}
        />
      }
    </FormControl>
  );
};

export default ProductSelection;
