import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Box,
  Card,
  CardHeader,
  Container,
  FormControlLabel,
  Grid,
  OutlinedInput,
  Radio,
  RadioGroup,
} from "@mui/material";
import { CreateItem, fetchDepartmentSettingsData } from "../../utils/fetchData";
import * as endpoints from "../../utils/constants";
import { toast } from "react-toastify";
import ProductsSelection from "../products/products-configs";
import BasePageConfigs from "../../pages/page-configs";
import { useLoadData } from "../../services/featching-data-service";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddPurchaseOrders({
  mode,
  data,
  supplierData,
  type: modeType,
}) {
  // fetching the inventories for ach purchase order when editing
  const { page, rowsPerPage } = BasePageConfigs();
  const payload = {
    offset: page * rowsPerPage,
    per_page: rowsPerPage,
    order_number: data ? data.order.order_number : "",
  };

  const { data: orderInventories } = useLoadData(
    payload,
    endpoints.GROUPED_SCHOOL_ORDERS_ENDPOINT
  );

  const [formattedOrderInventories, setFormattedOrderInventories] = useState(
    []
  );

  const supplier = supplierData || data?.order?.supplier;
  const [open, setOpen] = React.useState(false);
  const [lpoOpened, setLpoOpened] = React.useState(false);
  const [lsoOpened, setLsoOpened] = React.useState(false);
  const [department, setDepartment] = useState([]);
  const [orders, setOrders] = useState([]); // Changed initial state to empty array
  const [unitOfMeasurements, setUnitOfMeasurements] = useState([]);
  const [commonValues, setCommonValues] = React.useState({
    order_number: "",
    requisition_number: "N/A",
    supplier_id: "" || data?.order?.supplier.id,
    is_tendered: data?.order?.is_tendered || true,
    mode_of_payment: "",
    requesting_department: "",
    id: data?.order?.id || "",
  });
  console.log(orders);

  useEffect(() => {
    fetchDepartmentSettingsData(setDepartment);
  }, []);

  const handleClickOpen = (type) => {
    if (type === "lpo") {
      setLpoOpened(true);
      setLsoOpened(false);
    } else {
      setLsoOpened(true);
      setLpoOpened(false);
    }
    setOpen(true);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    window.location.reload();
  };

  const handleCommonInputChange = (field, value) => {
    setCommonValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleOrderInputChange = (index, field, value) => {
    // Determine if the index belongs to formattedOrderInventories or orders
    if (index < formattedOrderInventories.length) {
      // Update formattedOrderInventories
      const updatedInventories = [...formattedOrderInventories];
      updatedInventories[index][field] = value;
      setFormattedOrderInventories(updatedInventories);
    } else {
      // Calculate the index within the orders array
      const ordersIndex = index - formattedOrderInventories.length;
      // Update orders (for new items)
      const updatedOrders = [...orders];
      updatedOrders[ordersIndex][field] = value;
      setOrders(updatedOrders);
    }
  };

  const handleProductChange = (index, unitOfMeasurement) => {
    if (index < formattedOrderInventories.length) {
      // dont update the measurement if formatted
    } else {
      const ordersIndex = index - formattedOrderInventories.length;
      const updatedUnitOfMeasurements = [...unitOfMeasurements];
      updatedUnitOfMeasurements[ordersIndex] = unitOfMeasurement;
      setUnitOfMeasurements(updatedUnitOfMeasurements);
    }
  };

  const handleAddOrder = () => {
    setOrders([
      ...orders,
      {
        product_id: "",
        quantity: "",
        order_amount: "",
      },
    ]);
    setUnitOfMeasurements([...unitOfMeasurements, ""]);
  };

  const handleRemoveOrder = (index) => {
    const ordersIndex = index - formattedOrderInventories.length;
    const updatedOrders = orders.filter((_, i) => i !== ordersIndex);
    const updatedUnitOfMeasurements = unitOfMeasurements.filter(
      (_, i) => i !== ordersIndex
    );
    setOrders(updatedOrders);
    setUnitOfMeasurements(updatedUnitOfMeasurements);
  };

  const handleSubmit = async () => {
    if (
      commonValues.requisition_number === null ||
      !commonValues.requesting_department
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }

    let allOrderData = [];

    if (mode === "edit") {
      allOrderData = [...formattedOrderInventories, ...orders];
    } else {
      allOrderData = orders;
    }

    const concatenatedData = allOrderData.reduce(
      (acc, order) => {
        Object.keys(order).forEach((key) => {
          acc[key] = acc[key] ? `${acc[key]}, ${order[key]}` : order[key];
        });
        return acc;
      },
      { ...commonValues, supplier_id: supplier?.id }
    );
    console.log(concatenatedData);

    await CreateItem(concatenatedData, endpoints.NEW_ORDER_ENDPOINT);
  };

  useEffect(() => {
    if (orderInventories) {
      // Extract only the product details from each item in orderInventories
      const products = orderInventories.map((item) => ({
        ...item.order.product,
        order_amount: item.order.order_amount,
        quantity: item.order.quantity,
        product_id: item.order.product.id,
      }));

      setFormattedOrderInventories(products);
    }
  }, [orderInventories]);

  useEffect(() => {
    if (data) {
      setCommonValues({
        order_number: data.order.order_number || "",
        requisition_number: data.order.requisition_number || "",
        is_tendered: data.order.is_tendered || "No",
        mode_of_payment: data.order.product.unit_of_measurement || "",
        requesting_department: data.order.requesting_department || "",
        notes: data.order.notes || "",
        expected_by_date: data.order.expected_by_date || "",
        id: data?.order?.id || "",
      });

      setLpoOpened(modeType === "lpo");
      setLsoOpened(modeType === "lso");
    }
    if (mode === "edit") {
      handleOpen();
    }
  }, [data, mode, modeType]);

  return (
    <React.Fragment>
      <Button
        sx={{ marginTop: "15px" }}
        variant="contained"
        onClick={() => handleClickOpen("lpo")}
      >
        New Purchase Order
      </Button>
      <Button
        sx={{ marginLeft: "15px", marginTop: "15px" }}
        variant="contained"
        onClick={() => handleClickOpen("lso")}
      >
        New LSO Order
      </Button>

      <Dialog
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              variant="h6"
              component="div"
            ></Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <Container sx={{ marginTop: "1rem" }}>
            <Card sx={{ p: 2, display: "flex", flexWrap: "wrap" }}>
              <Box sx={{ width: "100%" }}>
                <CardHeader
                  subheader={`Fill in all the inputs to be able to ${
                    mode === "edit" ? "edit" : "create"
                  }  ${lpoOpened ? "PURCHASE ORDER" : "LSO ORDER"}`}
                  title={`${mode === "edit" ? "EDIT" : "CREATE A NEW"} ${
                    lpoOpened ? "PURCHASE ORDER" : "LSO ORDER"
                  } FOR ${supplier?.company_name}`}
                />
                <Divider />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <ListItem>
                    <FormControl fullWidth>
                      <p>
                        Requesting Department
                        <span style={{ color: "red", marginLeft: "5px" }}>
                          *{" "}
                        </span>
                      </p>
                      <Autocomplete
                        options={
                          department?.departments
                            ? department?.departments?.split(",")
                            : []
                        }
                        value={commonValues?.requesting_department}
                        onChange={(event, value) =>
                          handleCommonInputChange(
                            "requesting_department",
                            value
                          )
                        }
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" fullWidth />
                        )}
                      />
                    </FormControl>
                  </ListItem>
                  {[
                    {
                      labelName: lpoOpened
                        ? "LPO Number(no input required)"
                        : "LSO Number(no input required)",
                      field: "order_number",
                    },
                    {
                      labelName: "Requisition  Number",
                      field: "requisition_number",
                    },
                  ].map((inputField, index) => (
                    <ListItem
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                      }}
                    >
                      <p>
                        {inputField.labelName}
                        <span style={{ color: "red", marginLeft: "5px" }}>
                          *
                        </span>
                      </p>
                      {inputField.type === "date" ? (
                        <FormControl sx={{ width: "100%" }}>
                          <TextField
                            type="date"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                              handleCommonInputChange(
                                inputField.field,
                                e.target.value
                              )
                            }
                          />
                        </FormControl>
                      ) : (
                        <FormControl sx={{ width: "100%" }}>
                          <OutlinedInput
                            defaultValue=""
                            value={commonValues[inputField.field]}
                            fullWidth
                            onChange={(e) =>
                              handleCommonInputChange(
                                inputField.field,
                                e.target.value
                              )
                            }
                          />
                        </FormControl>
                      )}
                    </ListItem>
                  ))}
                  <ListItem>
                    <FormControl component="fieldset">
                      <p>
                        Is Tendered
                        <span style={{ color: "red" }}> *</span>
                      </p>
                      <RadioGroup
                        row
                        value={commonValues["is_tendered"]}
                        onChange={(e) =>
                          handleCommonInputChange("is_tendered", e.target.value)
                        }
                      >
                        {[
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                        ].map((option, optionIndex) => (
                          <FormControlLabel
                            key={optionIndex}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </ListItem>
                </Grid>
                <Grid item xs={12} md={12}>
                  {/* Render existing inventories on edit mode, editable */}
                  {mode === "edit" &&
                    formattedOrderInventories.map((inventory, index) => (
                      <Box
                        key={index}
                        sx={{
                          mb: 2,
                          p: 2,
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <ListItem>
                          <ProductsSelection
                            index={index}
                            setOrderValues={handleOrderInputChange}
                            setProduct={handleProductChange}
                            productType={lpoOpened ? "Inventories" : "Services"}
                            initialProduct={inventory}
                            mode={mode}
                            disabled={true}
                          />
                        </ListItem>

                        {[
                          {
                            labelName: `Quantity ${
                              inventory.unit_of_measurement || ""
                            }`,
                            field: "quantity",
                          },
                          {
                            labelName: "Total Order Amount",
                            field: "order_amount",
                          },
                        ].map(
                          (inputField, inputIndex) =>
                            !(lsoOpened && inputField.field === "quantity") && (
                              <ListItem
                                key={inputIndex}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "start",
                                }}
                              >
                                <p>
                                  {inputField.labelName}
                                  <span
                                    style={{ color: "red", marginLeft: "5px" }}
                                  >
                                    *{" "}
                                  </span>
                                </p>
                                <FormControl sx={{ width: "100%" }}>
                                  <OutlinedInput
                                    value={inventory[inputField.field]}
                                    fullWidth
                                    onChange={(e) =>
                                      handleOrderInputChange(
                                        index,
                                        inputField.field,
                                        e.target.value.replace(/,/g, "")
                                      )
                                    }
                                  />
                                </FormControl>
                              </ListItem>
                            )
                        )}
                      </Box>
                    ))}

                  {/* Render ProductsSelection for adding new inventories */}
                  {mode === "edit"
                    ? orders.map((order, index) => {
                        const totalIndex =
                          index + formattedOrderInventories.length;
                        return (
                          <Box
                            key={totalIndex}
                            sx={{
                              mb: 2,
                              p: 2,
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <ListItem>
                              <ProductsSelection
                                index={totalIndex}
                                setOrderValues={handleOrderInputChange}
                                setProduct={handleProductChange}
                                productType={
                                  lpoOpened ? "Inventories" : "Services"
                                }
                                initialProduct={null}
                                mode={mode}
                              />
                            </ListItem>

                            {[
                              {
                                labelName: `Quantity${
                                  unitOfMeasurements[index] || ""
                                }`,
                                field: "quantity",
                              },
                              {
                                labelName: "Total Order Amount",
                                field: "order_amount",
                              },
                            ].map(
                              (inputField, inputIndex) =>
                                !(
                                  lsoOpened && inputField.field === "quantity"
                                ) && (
                                  <ListItem
                                    key={inputIndex}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "start",
                                    }}
                                  >
                                    <p>
                                      {inputField.labelName}
                                      <span
                                        style={{
                                          color: "red",
                                          marginLeft: "5px",
                                        }}
                                      >
                                        *{" "}
                                      </span>
                                    </p>
                                    <FormControl sx={{ width: "100%" }}>
                                      <OutlinedInput
                                        value={order[inputField.field]}
                                        fullWidth
                                        onChange={(e) =>
                                          handleOrderInputChange(
                                            totalIndex,
                                            inputField.field,
                                            e.target.value.replace(/,/g, "")
                                          )
                                        }
                                      />
                                    </FormControl>
                                  </ListItem>
                                )
                            )}
                            {orders.length > 1 && (
                              <Button
                                color="secondary"
                                onClick={() => handleRemoveOrder(totalIndex)}
                                sx={{ marginTop: "10px" }}
                              >
                                Remove
                              </Button>
                            )}
                          </Box>
                        );
                      })
                    : orders.map((order, index) => (
                        <Box
                          key={index}
                          sx={{
                            mb: 2,
                            p: 2,
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <ListItem>
                            <ProductsSelection
                              index={index}
                              setOrderValues={handleOrderInputChange}
                              setProduct={handleProductChange}
                              productType={
                                lpoOpened ? "Inventories" : "Services"
                              }
                              initialProduct={null}
                              mode={mode}
                            />
                          </ListItem>

                          {[
                            {
                              labelName: `Quantity${
                                unitOfMeasurements[index] || ""
                              }`,
                              field: "quantity",
                            },
                            {
                              labelName: "Total Order Amount",
                              field: "order_amount",
                            },
                          ].map(
                            (inputField, inputIndex) =>
                              !(
                                lsoOpened && inputField.field === "quantity"
                              ) && (
                                <ListItem
                                  key={inputIndex}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "start",
                                  }}
                                >
                                  <p>
                                    {inputField.labelName}
                                    <span
                                      style={{
                                        color: "red",
                                        marginLeft: "5px",
                                      }}
                                    >
                                      *{" "}
                                    </span>
                                  </p>
                                  <FormControl sx={{ width: "100%" }}>
                                    <OutlinedInput
                                      value={order[inputField.field]}
                                      fullWidth
                                      onChange={(e) =>
                                        handleOrderInputChange(
                                          index,
                                          inputField.field,
                                          e.target.value.replace(/,/g, "")
                                        )
                                      }
                                    />
                                  </FormControl>
                                </ListItem>
                              )
                          )}
                          {orders.length > 1 && (
                            <Button
                              color="secondary"
                              onClick={() => handleRemoveOrder(index)}
                              sx={{ marginTop: "10px" }}
                            >
                              Remove
                            </Button>
                          )}
                        </Box>
                      ))}

                  <Button
                    variant="outlined"
                    onClick={handleAddOrder}
                    sx={{ marginTop: "10px" }}
                  >
                    ADD INVENTORY
                  </Button>
                </Grid>
              </Grid>
              <ListItem
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "start",
                }}
              >
                <Button
                  sx={{ width: "100%", marginRight: "5px" }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </ListItem>
            </Card>
          </Container>
        </List>
      </Dialog>
    </React.Fragment>
  );
}
