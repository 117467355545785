import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert"; // Icon for dropdown
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import ProgressBars from "../../utils/loading";
import { DateTimeFormate } from "../../utils/dateTime-fomater";
import { SeverityPill } from "../../components/severity-pill";
import { useNavigate } from "react-router-dom";
import { makeQuantityPositive } from "../../utils/quantity-converter";
import AddAllocation from "./add-allocations";
import ReceiveAllocation from "./receive-allocations";
import DeletePopup from "../../components/deletePopup";
import { DELETE_ALLOCATION_ENDPOINT } from "../../utils/constants";

export const AllocationTable = (props) => {
  const {
    count = 0,
    items = [],
    onPageChange = () => {},
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0,
    selected = [],
    isLoading = false,
    componentRef,
  } = props;

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openEditAllocationsData, setOpenEditAllocationsData] = useState({
    open: false,
    allocationsData: null,
  });
  const [dialogDeleteOpen, setDialogDeleteOpen] = React.useState(false);
  const [selectedEntity, setSelectedEntity] = React.useState({
    id: null,
    name: "",
  });
  const [selectedAllocation, setSelectedAllocation] = useState(null);

  const handleTableRowClick = (allocation) => {
    const { id, item_name } = allocation.allocation.product;
    navigate(`/inventory/${id}/${item_name.replace("/", "|")}`);
  };

  const handleMenuClick = (event, allocation) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedAllocation(allocation);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedAllocation(null);
  };

  const handleEditClick = (event) => {
    event.stopPropagation();
    setOpenEditAllocationsData({
      open: true,
      allocationsData: selectedAllocation,
    });
    handleMenuClose();
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    setDialogDeleteOpen(true);
    setSelectedEntity({
      id: selectedAllocation.allocation.id,
      type: `this ${selectedAllocation.allocation.product.item_name}  for  ${selectedAllocation.allocation.staff.full_name}`,
    });
    setAnchorEl(null);
  };

  return (
    <Card>
      <Box ref={componentRef} sx={{ minWidth: 800 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Item</TableCell>
                <TableCell>Quantity ISSUED</TableCell>
                <TableCell>In Stock</TableCell>
                <TableCell>Staff Name</TableCell>
                <TableCell>Department</TableCell>
                <TableCell>Processed By</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={10}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: 200,
                      }}
                    >
                      <ProgressBars />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : items.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={10}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: 200,
                      }}
                    >
                      <CustomNoRowsOverlay />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                items.map((allocation, index) => {
                  const isSelected = selected.includes(
                    allocation.allocation.id
                  );
                  const rowNum = page * rowsPerPage + index + 1;

                  return (
                    <TableRow
                      hover
                      key={allocation.allocation.id}
                      selected={isSelected}
                      onClick={() => handleTableRowClick(allocation)}
                    >
                      <TableCell>{rowNum}</TableCell>
                      <TableCell>
                        {allocation.allocation.product.item_name}
                      </TableCell>
                      <TableCell>
                        {`${makeQuantityPositive(
                          allocation.allocation.quantity
                        )}  ${
                          allocation.allocation.product.unit_of_measurement
                        }`}
                      </TableCell>
                      <TableCell>
                        {`${
                          parseFloat(allocation.allocation.current_stock_qty) +
                          parseFloat(allocation.allocation.quantity)
                        } ${allocation.allocation.product.unit_of_measurement}`}
                      </TableCell>
                      <TableCell>
                        {allocation.allocation.staff.full_name}
                      </TableCell>
                      <TableCell>
                        {allocation.allocation.to_department}
                      </TableCell>
                      <TableCell>
                        {allocation.allocation.processed_by.full_name}
                      </TableCell>
                      <TableCell>
                        {DateTimeFormate(allocation.allocation.date)}
                      </TableCell>
                      <TableCell>
                        <SeverityPill
                          color={
                            allocation.allocation.quantity < 1
                              ? "error"
                              : "success"
                          }
                        >
                          {allocation.allocation.quantity < 1
                            ? "ISSUED"
                            : "RECEIVED"}
                        </SeverityPill>
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={(event) =>
                            handleMenuClick(event, allocation)
                          }
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                        >
                          <MenuItem onClick={handleEditClick}>Edit</MenuItem>
                          <MenuItem onClick={handleDeleteClick}>
                            Delete
                          </MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ marginLeft: 16 }}
      >
        <TablePagination
          component="div"
          count={count}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[25, 50, 100]}
        />
      </Grid>
      <div style={{ display: "none" }}>
        {openEditAllocationsData.open &&
          (openEditAllocationsData.allocationsData.allocation.quantity < 1 ? (
            <AddAllocation
              mode="edit"
              data={openEditAllocationsData.allocationsData}
            />
          ) : (
            <ReceiveAllocation
              mode="edit"
              data={openEditAllocationsData.allocationsData}
            />
          ))}
      </div>
      {dialogDeleteOpen && (
        <DeletePopup
          dialogOpen={dialogDeleteOpen}
          setDialogOpen={setDialogDeleteOpen}
          selectedEntity={selectedEntity}
          urlLink={DELETE_ALLOCATION_ENDPOINT}
        />
      )}
    </Card>
  );
};

AllocationTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.array,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  selected: PropTypes.array,
  isLoading: PropTypes.bool,
};
