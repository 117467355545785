import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  AppBar,
} from "@mui/material";
import { makeNetworkCall } from "../utils/fetchData";
import { toast } from "react-toastify";

const DeletePopup = ({
  dialogOpen,
  setDialogOpen,
  selectedEntity,
  urlLink,
}) => {
  const [entities, setEntities] = useState([]);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  console.log(entities);

  const handleCancel = () => {
    setDialogOpen(false);
  };

  const handleAddEntryConfirm = async () => {
    const deleteData = await makeNetworkCall(
      urlLink,
      {
        id: selectedEntity.id,
      },
      setEntities,
      null,
      setLoading,
      null
    );

    const info = deleteData.message;

    if (deleteData.success === true || deleteData.status === 200) {
      toast.dark(info);
      handleCancel();
      window.location.reload();
    } else {
      toast.error(info);
      setError(info);
    }
  };

  return (
    <Dialog open={dialogOpen} onClose={handleCancel}>
      <AppBar sx={{ position: "relative" }}>
        <DialogTitle sx={{ textTransform: "uppercase" }}>
          Confirm to delete
        </DialogTitle>
      </AppBar>

      <DialogContent
        sx={{
          minWidth: "450px",
          minHeight: "150px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {loading ? (
          <Typography>Loading .........</Typography>
        ) : (
          <>
            {error ? (
              <Typography>
                <strong style={{ color: "red" }}> {error}?</strong>
              </Typography>
            ) : (
              <Typography>
                Are you sure you want to delete
                <strong> {selectedEntity.type}?</strong>
              </Typography>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} variant="contained" color="error">
          Cancel
        </Button>
        <Button onClick={handleAddEntryConfirm} variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePopup;
