// PaymentTable component
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Divider,
  Grid,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import ProgressBars from "../../utils/loading";
import CustomNoRowsOverlay from "../../components/CustomNoRowsOverlay";
import { DateTimeFormateTimeStamp } from "../../utils/dateTime-fomater";
import { useNavigate } from "react-router-dom";
import { SeverityPill } from "../../components/severity-pill";
import { formatDisplayNumber } from "../../utils/number-formter";
import React, { useState } from "react";
import ImageViewer from "../../components/image-popup";
import AddDelivery from "./add-deliveries";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeletePopup from "../../components/deletePopup";
import { DELETE_DELIVERY_ENDPOINT } from "../../utils/constants";

export const DeliveriesTable = (props) => {
  const {
    totals = {},
    items = [],
    onPageChange = () => {},
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0,
    selected = [],
    isLoading = false,
    componentRef,
  } = props;
  const navigate = useNavigate();

  // State to handle the dropdown menu
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDelivery, setSelectedDelivery] = useState(null);
  const [dialogDeleteOpen, setDialogDeleteOpen] = React.useState(false);
  const [selectedEntity, setSelectedEntity] = React.useState({
    id: null,
    name: "",
  });
  const [openEdit, setOpenEdit] = React.useState({
    open: false,
    data: null,
    type: "",
  });
  const [open, setOpen] = React.useState(false);

  const handleNavigate = (purchaseOrder) => {
    const { id } = purchaseOrder;
    navigate(`/purchase-order/${id}`);
  };

  const handleMenuOpen = (event, delivery) => {
    setAnchorEl(event.currentTarget);
    setSelectedDelivery(delivery);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedDelivery(null);
  };

  const handleEditClick = () => {
    setOpenEdit({ open: true, data: selectedDelivery });  
    handleMenuClose();
  };

  const handleDeleteClick = (delivery) => {
    setDialogDeleteOpen(true);
    setSelectedEntity({
      id: selectedDelivery.delivery.id,
      type: `this delivery (LPO Number.${selectedDelivery.delivery.purchase_order.order_number})`,
    });
    setAnchorEl(null);
    handleMenuClose();
  };

  return (
    <Card>
      <Box ref={componentRef} sx={{ minWidth: 800 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>LPO Number</TableCell>
                <TableCell>Item</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Invoiced Amount</TableCell>
                <TableCell>Total Delivered</TableCell>
                <TableCell>In Stock</TableCell>
                <TableCell>Delivered By</TableCell>
                <TableCell>Received By</TableCell>
                <TableCell>Supplier</TableCell>
                <TableCell>Date Delivered</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>

            {isLoading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: 200,
                      }}
                    >
                      <ProgressBars />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : items.length === 0 ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: 200,
                      }}
                    >
                      <CustomNoRowsOverlay />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {items.map((delivery, index) => {
                  const rowNum = page * rowsPerPage + index + 1;
                  const isSelected = selected.includes(delivery.delivery.id);

                  return (
                    <TableRow hover key={delivery.id} selected={isSelected}>
                      <TableCell
                        onClick={() =>
                          handleNavigate(delivery.delivery.purchase_order)
                        }
                      >
                        {rowNum}
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          handleNavigate(delivery.delivery.purchase_order)
                        }
                      >
                        <ListItemText
                          primary={
                            delivery.delivery.purchase_order.order_number
                          }
                          primaryTypographyProps={{ variant: "subtitle1" }}
                          secondary={
                            delivery.delivery.delivery_image && (
                              <ImageViewer
                                imageUrl={delivery.delivery.delivery_image}
                                title="Delivery image"
                                open={open}
                                setOpen={setOpen}
                              />
                            )
                          }
                          secondaryTypographyProps={{ variant: "caption" }}
                        />
                      </TableCell>
                      <TableCell
                        onClick={() =>
                          handleNavigate(delivery.delivery.purchase_order)
                        }
                      >
                        {delivery.delivery.purchase_order.product.item_name}
                      </TableCell>
                      <TableCell>
                        <SeverityPill color="success">
                          {`${delivery.delivery.quantity} ${delivery.delivery.purchase_order.product.unit_of_measurement}`}
                        </SeverityPill>
                      </TableCell>
                      <TableCell>
                        <SeverityPill color="warning">
                          {`KES. ${formatDisplayNumber(
                            delivery.delivery.invoice_amount
                          )}`}
                        </SeverityPill>
                      </TableCell>
                      <TableCell>
                        {`${
                          parseFloat(
                            delivery.delivery.current_total_delivered
                          ) + parseFloat(delivery.delivery.quantity)
                        } ${
                          delivery.delivery.purchase_order.product
                            .unit_of_measurement
                        }`}
                      </TableCell>
                      <TableCell>
                        {`${formatDisplayNumber(
                          parseFloat(delivery.delivery.current_stock_qty) +
                            parseFloat(delivery.delivery.quantity)
                        )}`}
                      </TableCell>
                      <TableCell>{delivery.delivery.delivered_by}</TableCell>
                      <TableCell>
                        {delivery.delivery.processed_by.full_name}
                      </TableCell>
                      <TableCell>
                        <ListItemText
                          primary={`${delivery.delivery.purchase_order.supplier.full_name} - ${delivery.delivery.purchase_order.supplier.phone_number}`}
                          secondary={
                            delivery.delivery.purchase_order.supplier
                              .company_name
                          }
                          secondaryTypographyProps={{ variant: "caption" }}
                        />
                      </TableCell>
                      <TableCell>
                        {DateTimeFormateTimeStamp(delivery.delivery.timestamp)}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          onClick={(event) => handleMenuOpen(event, delivery)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Box>

      <TablePagination
        component="div"
        count={totals.total}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[25, 50, 100]}
      />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEditClick}>Edit</MenuItem>
        <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
      </Menu>

      {openEdit.open && (
        <div style={{ display: "none" }}>
          <AddDelivery mode="edit" type={openEdit.type} data={openEdit.data} />
        </div>
      )}
      {dialogDeleteOpen && (
        <DeletePopup
          dialogOpen={dialogDeleteOpen}
          setDialogOpen={setDialogDeleteOpen}
          selectedEntity={selectedEntity}
          urlLink={DELETE_DELIVERY_ENDPOINT}
        />
      )}
    </Card>
  );
};

DeliveriesTable.propTypes = {
  totals: PropTypes.object,
  items: PropTypes.array,
  onDeselectAll: PropTypes.func,
  onDeselectOne: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onSelectAll: PropTypes.func,
  onSelectOne: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  selected: PropTypes.array,
  isLoading: PropTypes.bool,
};
