import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import {
  Box,
  Card,
  CardHeader,
  Container,
  Divider,
  FormControl,
  Grid,
  List,
  ListItem,
  OutlinedInput,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { CreateItem } from "../../utils/fetchData";
import * as endpoints from "../../utils/constants";
import { SCHOOL_STAFF_ENDPOINT } from "../../utils/constants";
import { toast } from "react-toastify";
import ProductsSelection from "../products/products-configs";
import { useLoadData } from "../../services/featching-data-service";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddAllocation({ mode, data, page }) {
  const payload = {
    page: 0,
    per_page: 200,
  };
  const { data: staffs } = useLoadData(payload, SCHOOL_STAFF_ENDPOINT);
  const [open, setOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    staff_id: data?.allocation?.staff?.id || "",
    id: "",
    notes: "",
  });
  const [allocations, setAllocations] = useState([
    {
      quantity:
        data?.allocation?.quantity * -1 >= 0
          ? data?.allocation?.quantity * -1
          : "",
      product_id: data?.allocation?.product?.id || "",
    },
  ]);
  const [unitOfMeasurements, setUnitOfMeasurements] = useState("");
  const [selectedStaff, setSelectedStaff] = useState(
    data?.allocation?.staff || null
  );

  const handleAddAllocations = () => {
    setAllocations([...allocations, { quantity: "", product_id: "" }]);
  };

  const handleRemoveAllocations = (index) => {
    const updatedAllocations = allocations.filter((_, i) => i !== index);
    setAllocations(updatedAllocations);
  };

  const handleOrderInputChange = (index, field, value) => {
    const updatedAllocations = [...allocations];
    updatedAllocations[index][field] = value;
    setAllocations(updatedAllocations);
  };

  const handleProductChange = (index, unitOfMeasurement) => {
    const updatedUnitOfMeasurements = [...unitOfMeasurements];
    updatedUnitOfMeasurements[index] = unitOfMeasurement;
    setUnitOfMeasurements(updatedUnitOfMeasurements);
  };

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleStaffSelection = (_, value) => {
    setSelectedStaff(value);
    if (value) {
      setFormValues((prevValues) => ({
        ...prevValues,
        staff_id: value.id,
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        staff_id: "",
      }));
    }
  };

  const handleProductAllocationSubmit = async () => {
    if (
      !formValues.staff_id ||
      allocations.some((a) => !a.product_id || !a.quantity)
    ) {
      toast.error("Please fill in all required fields");
    } else {
      const postData = allocations.reduce(
        (acc, allocation) => {
          const negativeQuantity = -parseFloat(allocation.quantity);
          acc.product_id = acc.product_id
            ? `${acc.product_id}, ${allocation.product_id}`
            : allocation.product_id;
          acc.quantity = acc.quantity
            ? `${acc.quantity}, ${negativeQuantity}`
            : negativeQuantity.toString();
          return acc;
        },
        { ...formValues }
      );

      CreateItem(postData, endpoints.NEW_STAFF_ISSUANCE_ENDPOINT);
    }
  };

  const filterStaffs = (inputValue) => {
    return staffs.filter(
      (staff) =>
        staff.full_name.toLowerCase().includes(inputValue.toLowerCase()) ||
        staff.id_number.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  useEffect(() => {
    if (mode === "edit" && data) {
      setAllocations([
        {
          quantity:
            data?.allocation?.quantity * -1 >= 0
              ? data?.allocation?.quantity * -1
              : "",
          product_id: data?.allocation?.product?.id || "",
        },
      ]);
      setFormValues({
        staff_id: data?.allocation?.staff?.id || "",
        id: data?.allocation?.id,
        notes: data?.allocation?.notes,
      });
      setSelectedStaff(data?.allocation?.staff || null);
      handleClickOpen();
    }
  }, [mode, data]);

  return (
    <React.Fragment>
      {mode === "edit" ? (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleClickOpen}
        >
          Edit
        </Button>
      ) : (
        <Button
          startIcon={<PlusIcon fontSize="small" />}
          variant="contained"
          onClick={handleClickOpen}
        >
          Issue an Item
        </Button>
      )}

      <Dialog
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {/* form */}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>

        <List>
          <Container sx={{ marginTop: "1rem" }}>
            <Card sx={{ p: 2, display: "flex", flexWrap: "wrap" }}>
              <Box sx={{ width: "100%" }}>
                <CardHeader
                  subheader="Fill in all the inputs to be able to issue a product to a given department"
                  title="Issue an Item"
                />
                <Divider />
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ListItem
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "start",
                    }}
                  >
                    <p>
                      Staff
                      <span style={{ color: "red" }}> *</span>
                    </p>

                    <FormControl fullWidth>
                      <Autocomplete
                        options={staffs ? staffs : []}
                        getOptionLabel={(option) =>
                          `${option?.full_name?.toUpperCase()} - ${option?.department?.toUpperCase()}`
                        }
                        value={selectedStaff}
                        onChange={handleStaffSelection}
                        filterOptions={(options, params) => {
                          const filtered = filterStaffs(params.inputValue);
                          return filtered;
                        }}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" fullWidth />
                        )}
                      />
                    </FormControl>
                  </ListItem>
                </Grid>
                <Grid item xs={12} md={6}>
                  {allocations &&
                    allocations.map((allocation, index) => (
                      <Box
                        key={index}
                        sx={{
                          mb: 2,
                          p: 2,
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                        }}
                      >
                        <ListItem>
                          <ProductsSelection
                            index={index}
                            setOrderValues={handleOrderInputChange}
                            setProduct={handleProductChange}
                            productType="Inventories"
                            initialProduct={data?.allocation?.product}
                          />
                        </ListItem>
                        <ListItem
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "start",
                          }}
                        >
                          <p>
                            {`Quantity ${unitOfMeasurements}`}
                            <span style={{ color: "red" }}> *</span>
                          </p>

                          <FormControl fullWidth>
                            <OutlinedInput
                              value={allocation.quantity}
                              fullWidth
                              onChange={(e) =>
                                handleOrderInputChange(
                                  index,
                                  "quantity",
                                  e.target.value.replace(/,/g, "")
                                )
                              }
                            />
                          </FormControl>
                        </ListItem>
                        {allocations?.length > 1 && (
                          <Button
                            color="secondary"
                            onClick={() => handleRemoveAllocations(index)}
                            sx={{ marginTop: "10px" }}
                          >
                            Remove
                          </Button>
                        )}
                      </Box>
                    ))}
                  <Button
                    variant="outlined"
                    onClick={handleAddAllocations}
                    sx={{ marginTop: "10px" }}
                  >
                    Issue Another Item
                  </Button>
                </Grid>
              </Grid>
              <ListItem
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "start",
                }}
              >
                <Button
                  onClick={handleProductAllocationSubmit}
                  sx={{ width: "100%", marginRight: "5px" }}
                  variant="contained"
                >
                  Submit
                </Button>
              </ListItem>
            </Card>
          </Container>
        </List>
      </Dialog>
    </React.Fragment>
  );
}
